import api from '@/services/api'
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils'
export default {
    getAllCampaigns (includeBursts = false, includeStatus = false, includeArchived = false) {
        return api().get('Campaign?includeBursts='+includeBursts+'&includeStatus='+includeStatus+'&includeArchived='+includeArchived)
    },
    // Create Campaign
    createCampaign (data) {
        return api().post('Campaign', data)
    },
    // Get campaign By ID
    getCampaign (id, includeStatus = false) {
        return api().get('Campaign/GetCampaign?campaignId='+id+'&includeStatus='+includeStatus)
    },
    // Delete campaign By ID
    deleteCampaign (id) {
        return api().delete('Campaign/' + id)
    },
    // Get campaign status
    campaignStatus (id) {
        return api().get('Campaign/GetCampaignStatus/' + id)
    },
    // Get all delivery types
    deliveryTypes () {
        return api().get('Campaign/GetDeliveryTypes')
    },
    // Edit campaign
    editCampaign (data) {
        return api().put('Campaign', data)
    },
    // Add a burst
    addBurst (data) {
        return api().post('Campaign/AddBurst', data)
    },
    // Add a burst screen
    addBurstScreen (data) {
        return api().post('Campaign/AddBurstScreen', data)
    },
    // Edit a burst screen
    editBurstScreen (data) {
        return api().put('Campaign/UpdateBurstScreen', data)
    },
    // Edit a burst screen
    editBurst (data) {
        return api().put('Campaign/UpdateBurst', data)
    },
    // Remove a burst
    removeBurst (data) {
        return api().post('Campaign/RemoveBurst', data)
    },
    // Remove a burst screen
    removeBurstScreen (data) {
        return api().post('Campaign/RemoveBurstScreen', data)
    },
    // Get Asset
    getAssets (campaignId) {
        return api().get('Campaign/GetAssets/' +  campaignId)
    },
    // Add Asset
    addAsset (data) {
        return api().post('Campaign/AddAsset', data)
    },
    // Get Asset for flight
    getAssetsForFlight (flightId) {
        return api().get('Airport/GetAssetsForFlight/' + flightId)
    },
    // Add Asset
    addAssetForFlight (data) {
        return api().post('Airport/AddAssetToFlight', data)
    },
    // Remove Asset
    removeAssetFromFlight (flightAssetId) {
        return api().delete('Airport/RemoveAssetFromFlight/' + flightAssetId)
    },
    // Add Content
    addContent (data) {
        return api().post('Campaign/AddContent', data)
    },
    // Replace Content
    replaceContentArtwork(data){
        return api().post('Campaign/ReplaceContent', data)
    },
    // Clone Content
    cloneContent (data) {
        return api().post('Campaign/CloneContent', data)
    },
    // Add Link
    addLink (data) {
        return api().post('Campaign/AddLink', data)
    },
    // GetLinkId Get Link Group
    getLinkId (data) {
        return api().post('Campaign/GetLinkId', data)
    },
    // Add Placeholder
    addDraftPlaceholder (campaignBurstScreenId, text) {
        return api().get('Campaign/AddDraftPlaceholder?campaignBurstScreenId=' + campaignBurstScreenId + "&text=" + text)
    },
    // Set logo
    setLogo (data) {
        return api().post('Campaign/SetLogo', data)
    },
    // get burst content
    getBurstContent (id) {
        return api().get('Campaign/GetBurstContent/' + id)
    },
    // delete content from Burst
    deleteBurstContent (data) {
        return api().post('Campaign/RemoveContent', data)
    },
    // Set stakeholder
    setStakeholders (data) {
        return api().post('Campaign/SetStakeholders', data)
    },
    // get all users by campaign id
    getAllUsers (id) {
        return api().get('Campaign/GetUsers/' + id)
    },
    // Add user to campaign
    setUsers (data) {
        return api().post('Campaign/AddUser', data)
    },
    // Delete user from campaign
    deleteUser (data) {
        return api().post('Campaign/RemoveUser', data)
    },
    // Get contents of campaign burst screen
    getCampaignBurstScreenContents (id) {
        return api().get('Campaign/GetScreenContent/' + id)
    },
    // Set Trigger
    setTriggerOnContent (data) {
        return api().put('Campaign/UpdateContent/SetTrigger', data)
    },
    // Set Trigger
    setTriggersOnContent (data) {
        return api().put('Campaign/UpdateContent/SetTriggers', data)
    },
    // Log Trigger Update
    logTriggerUpdate (data) {
        return api().put('Campaign/LogUpdateContent', data)
    },
    // Post location list
    uploadLocationList (data) {
        return api().post('Campaign/UploadLocationList', data)
    },

    // Post location list
    uploadLocationListByContainer (data) {
        return api().post('Campaign/uploadLocationListByContainer', data)
    },

    // Saved Location List Data
    getSavedLocationListData (campaignId, campaignBurstId, screenId) {
        return api().get('Campaign/GetLocationListObjectData?campaignId=' + campaignId + '&campaignBurstId=' + campaignBurstId + '&screenId=' + screenId)
    },

    // Get location list Media Item
    getLocationListMedia(campaignId, campaignBurstId, screenId) {
        return api().get('Campaign/GetLocationListMedia?campaignId=' + campaignId + '&campaignBurstId=' + campaignBurstId + '&screenId=' + screenId)
    },

    // Get location list
    getLocationList (id) {
        return api().get('Campaign/GetLocationList/' + id)
    },
    getArtworkSpecs(campaignId, campaignBurstId) {
        return api().get('Campaign/ArtworkSpecs?campaignId=' + campaignId + '&campaignBurstId=' + campaignBurstId, { responseType: "blob" })
    },

    // Resources
    getScreenResources (id) {
        return api().get('Campaign/GetResources/screen/' + id)
    },
    getBurstResources (id) {
        return api().get('Campaign/GetResources/burst/' + id)
    },
    getCampaignResources (id) {
        return api().get('Campaign/GetResources/campaign/' + id)
    },
    setResource (data) {
        return api().post('Campaign/SetResource', data)
    },
    getResourceTypes (level) {
        return api().get('Campaign/GetResourceTypes/'+level)
    },
    addResourceLink (data) {
        return api().post('Campaign/AddResourceLink', data)
    },

    // FTP Details
    getFtpDetails (id) {
        return api().get('Campaign/GetFtpDetails/' + id)
    },

    // Upload a media item to a campaign
    uploadMedia (data, config = {}) {
        return api().post('Media/Upload', data, config)
    },
    // PoP Controllers
    addTag (data) {
        return api().post('Campaign/AddTag', data)
    },
    updateTag (data){
        return api().put('Campaign/UpdateTag', data)
    },
    addPoP (data) {
        return api().post('Campaign/AddProofOfPlay', data)
    },
    updatePoP (data) {
        return api().put('Campaign/UpdateProofOfPlay', data)
    },
    getPoPs (data) {
        return api().get('Campaign/GetProofOfPlays'+data)
    },
    deletePoP (id) {
        return api().delete('Campaign/RemoveProofOfPlay/'+id)
    },
    // PoP Tag Controllers
    addPoPTag(data) {
        return api().post('Campaign/AddProofOfPlayTag', data)
    },
    updatePoPTag(data) {
        return api().put('Campaign/UpdateProofOfPlayTag', data)
    },
    deletePoPTag(id) {
        return api().delete('Campaign/RemoveProofOfPlayTag/'+id)
    },
    getPoPTagCategories() {
        return api().get('Campaign/GetCategoriesTags')
    },
    // Upload B64 Media
    uploadB64 (data) {
        return api().post('Media/UploadBase64', data)
    },

    // Airport Controllers
    addFlight (data) {
        return api().post('Airport/AddFlight/', data)
    },
    getFlights (data) {
        return api().post('Airport/GetFlights', data)
    },
    setPackage (data) {
        return api().post('Airport/SetPackage', data)
    },
    getPackage (flightId, packageType) {
        return api().get('Airport/GetPackage?flightId='+flightId+'&type='+packageType, { responseType: "blob" })
    },
    setActivePackage (id) {
        return api().get('Airport/SetActive/' + id)
    },
    getPreview (data) {
        return api().post('Airport/Preview', data)
    },
    getCampaignRecall (data) {
        return api().post('Airport/CampaignRecall', data)
    },
    getScreengrabPackage (data) {
        return api().post('Airport/ScreengrabPackage', data , { responseType: "blob" })
    },
    getScreengrab (data) {
        return api().post('Airport/Screengrab', data, { responseType: "blob" })
    },
    runFlightCheck(id) {
        return api().get('Airport/RunFlightCheck/' + id)
    },
    // Campaign Locations for Map
    getCampaignLocations(activeOnly)
    {
        return api().get('Campaign/GetCampaignLocations?activeOnly=' + activeOnly)
    },
    // Set Campaign Burst Owner
    /*
        "id": 15, // this is the CampaignBurstId
        "ownerId: 1 // this is the userId
    */
    setBurstOwner(data)
    {
        return api().put('Campaign/SetCampaignBurstOwner', data)
    },
    // LBC Linked Formats
    getLinkedFormats(contentId) {
        return api().get('Campaign/GetLinkedContent?campaignBurstScreenContentId='+contentId)
    },

    getLinkedCampaigns() {
        return api().get('Campaign/GetLinkedCampaigns')
    },
    // Save linked metadata
    saveLinkedMetadata(data) {
        console.log(data)
        return api().post('Campaign/SaveLinkedMetadata', data)
    },

    getLinkedCampaignBurstScreens(burstId) {
        return api().get('Campaign/GetLinkedCampaignBurstScreens?burstId='+burstId)
    },

    archiveCampaign(id, archive) {
        return api().get('Campaign/ArchiveCampaign?campaignId='+id+'&archive='+archive)
    }
}

